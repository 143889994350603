import { useMeQuery } from '@store/api/user.api';
import React from 'react';
import { ContactRequestsCard } from './ContactRequestsCard';
import { ContactRequestLink } from './ContactRequestLink';
import { AllowContactForm } from './AllowContactForm.1';
import { generateRandomString } from './generateRandomString';

export const ContactProfileForm = ({ profile }: any) => {
  const { data: user } = useMeQuery();
  const [type, setType] = React.useState<'my-contact' | 'my-send-contact'>(
    user?.type === 'volunteer' ? 'my-send-contact' : 'my-contact',
  );
  const [randomKey, setRandomKey] = React.useState<string>(type);

  const setTypeWithReload = (type: 'my-contact' | 'my-send-contact') => {
    if (type === 'my-send-contact') {
      setRandomKey(generateRandomString());
    }

    setType(type);
  };

  return (
    <div className="max-w-[1080px] mx-auto px-4 pt-3 pb-8 ">
      <ContactRequestLink user={user!} type={type} onClick={setTypeWithReload} />
      {/* <AllowContactForm profile={profile} /> */}
      <ContactRequestsCard type={type} key={randomKey} />
    </div>
  );
};
