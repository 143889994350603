import { ApplicantType } from '@shared/enums';
import { getRegCodeValidation } from '@shared/utils';
import {
  FIELD_ABOUT,
  FIELD_APPLICANT_TYPE,
  FIELD_DISTRICT,
  FIELD_EMAIL,
  FIELD_LOCALITY,
  FIELD_NAME,
  FIELD_REGION,
  FIELD_SOCIAL_TAG,
  FIELD_STREET,
  FIELD_AVATAR,
} from '@widgets/signUp/signUpInNeed/signUpInNeedPrivatePerson/constants';
import {
  FIELD_COUNTRY,
  FIELD_LEGAL_NAME,
  FIELD_REG_CODE,
} from '@widgets/signUp/signUpVolunteer/signUpVolunteerLegalPerson/constants';
import { FIELD_PHONE } from '@widgets/signUp/signUpVolunteer/signUpVolunteerPrivatePerson/constants';
import * as yup from 'yup';
import {
  FIELD_ALLOW_SHARE_EMAIL,
  FIELD_ALLOW_SHARE_PHONE,
} from '../signUp/signUpInNeed/signUpInNeedPrivatePerson/constants';

export function getValidationSchemaProfile(language: string, applicantType: ApplicantType) {
  const regCodeValidation = getRegCodeValidation(language);
  const isApplicantTypeLegalPerson = applicantType === ApplicantType.LegalEntity;
  const regCodeValidationField = isApplicantTypeLegalPerson ? { [FIELD_REG_CODE]: regCodeValidation } : {};
  const legalNameValidation = isApplicantTypeLegalPerson
    ? { [FIELD_LEGAL_NAME]: yup.string().trim().required('errors.required') }
    : {};

  return yup.lazy((_, options) => {
    const phoneValidationResult = options.context?.getPhoneInnerValidationResult?.();

    return yup.object().shape({
      [FIELD_EMAIL]: yup.string().trim().email('errors.invalid-email'),
      [FIELD_NAME]: yup.string().trim().required('errors.required'),
      ...legalNameValidation,
      ...regCodeValidationField,
      [FIELD_PHONE]: yup
        .string()
        .trim()
        .required('errors.required')
        .matches(/^\+[0-9]+/i, 'errors.invalid-phone-number')
        .test({
          test: () => phoneValidationResult?.isValidPhone || false,
          message: phoneValidationResult?.phoneErrorMessage || '',
        }),
    });
  });
}

export const profileContract = {
  [FIELD_AVATAR]: 'avatar',
  [FIELD_NAME]: 'contactName',
  [FIELD_PHONE]: 'phoneNumber',
  [FIELD_COUNTRY]: 'country',
  [FIELD_REGION]: 'regionId',
  [FIELD_LOCALITY]: 'localityId',
  [FIELD_DISTRICT]: 'district',
  [FIELD_STREET]: 'streetName',
  [FIELD_ABOUT]: 'description',
  [FIELD_LEGAL_NAME]: 'legalName',
  [FIELD_REG_CODE]: 'regCode',
  [FIELD_EMAIL]: 'email',
  [FIELD_SOCIAL_TAG]: 'groups',
  [FIELD_APPLICANT_TYPE]: 'applicantType',
  [FIELD_ALLOW_SHARE_EMAIL]: 'allowShareEmail',
  [FIELD_ALLOW_SHARE_PHONE]: 'allowSharePhone',
};

export const profileAddressFields = [FIELD_COUNTRY, FIELD_REGION, FIELD_LOCALITY, FIELD_DISTRICT, FIELD_STREET];
