import { useMemo } from 'react';
import { useLocation } from 'react-use';

export function useSearchParams() {
  const { search = '' } = useLocation();

  return useMemo<URLSearchParams | null>(() => {
    try {
      return new URLSearchParams(search);
    } catch (err) {
      return null;
    }
  }, [search]);
}
