import { ProfileFormWrapper } from '@widgets/profile/ProfileFormWrapper';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const ProfilePage = () => {
  const { t } = useTranslation();

  return (
    <div className="px-4 pt-6 pb-12 lg:pt-12">
      <h1 className="text-xl md:text-2xl text-center">{t('my-profile')}</h1>
      <ProfileFormWrapper />
    </div>
  );
};

export default ProfilePage;
