import { isEmptyObject } from '@shared/utils';
import { toFormData } from '@shared/utils/dom';
import { profileAddressFields, profileContract } from './constants';

export function createProfilePostData(dirtyFields: any, data: any) {
  const dataFiltered = {};

  const result = { address: {} };
  dirtyFields.forEach((key: string) => {
    dataFiltered[key] = data[key];
  });

  Object.entries(dataFiltered).forEach(([key, value]) => {
    profileAddressFields.includes(key)
      ? (result.address[profileContract[key]] = value)
      : (result[profileContract[key]] = value);
  });

  if (isEmptyObject(result.address)) {
    delete result.address;
  }

  /**
   * NOTE: strange logic above, so we use function to convert js object to form data
   */
  return toFormData(result);
}
