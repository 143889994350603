import { useGetIconOptimized } from '@shared/hooks/useGetIconOptimized';
import { useNotify } from '@shared/hooks/useNotify';
import { Button } from '@shared/ui/buttons/Button';
import { ModalBasic } from '@shared/ui/modal/ModalBasic';
import { useDeleteContactRequestMutation } from '@store/api/contact-request.api';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type DeleteRequestContactModalProps = {
  isVisible: boolean;
  onClose: () => void;
  onApproveHandler: () => void;
  disabled: boolean;
  isLoading: boolean;
};

const DeleteRequestContactModal = ({
  isVisible,
  onClose,
  onApproveHandler,
  disabled,
  isLoading,
}: DeleteRequestContactModalProps) => {
  const iconOptimized = useGetIconOptimized('circle-ukraine-heart');

  return (
    <ModalBasic
      isVisible={isVisible}
      title="delete-contact-request.label"
      secondaryBtnLabel="cancel"
      approveBtnLabel="confirm"
      imageHeading={{ optimized: iconOptimized }}
      onClose={onClose}
      onApprove={() => {
        onApproveHandler();
      }}
      isLoading={isLoading}
      isApproveBtnDisabled={disabled}
    />
  );
};

export const DeleteContactRequestButton = ({ crId }: { crId: number }) => {
  const { t } = useTranslation();
  const notify = useNotify();
  const [isVisible, setIsvisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteHandler] = useDeleteContactRequestMutation();

  const onApproveHandler = async () => {
    setIsLoading(true);
    try {
      await deleteHandler({ id: crId }).unwrap();
    } catch (error) {
      notify('notifications.password-updated', {
        autoClose: 2000,
      });
    }
    setIsLoading(false);
  };

  return (
    <div className="mb-5">
      <Button
        as="button"
        variant="rich-blue"
        onClick={() => setIsvisible(true)}
        size="tiny"
        className="h-[32px] text-xxs py-0.5 px-5"
      >
        {t('delete-contact-request')}
      </Button>
      <DeleteRequestContactModal
        isVisible={isVisible}
        onClose={() => setIsvisible(false)}
        disabled={false}
        onApproveHandler={onApproveHandler}
        isLoading={isLoading}
      />
    </div>
  );
};
