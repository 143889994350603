import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useLanguage } from '@hooks/useLanguage';

import { transliterate } from '@shared/utils/strings';
import { LocationIcon } from '@shared/icons/location-icon';

import { Element } from '@shared/interfaces/element.interface';

import { CustomerProfileAddress as Address } from '../types';

interface CustomerProfileAddressProps extends Element {
  address: Address;
}

const useComponentState = ({ className, address }: CustomerProfileAddressProps) => {
  const { t } = useTranslation();
  const language = useLanguage();

  const region = useMemo(() => transliterate(address.region || '', language), [address.region, language]);
  const district = useMemo(() => transliterate(address.district || '', language), [address.district, language]);
  const locality = useMemo(() => transliterate(address.locality || '', language), [address.locality, language]);

  const regionText = region ? t(region) + ',' : '';
  const districtText = district ? `${district} ${t('district')}` : '';
  const localityText = locality ? locality : '';

  const addressText =
    regionText || districtText || localityText ? `${regionText} ${districtText} ${localityText}`.trim() : '-';

  return {
    className: clsx('flex items-center space-x-2 md:space-x-4 text-xxs text-black', className),

    region: regionText,
    district: districtText,
    locality: localityText,
    addressText,
  };
};

export const CustomerProfileAddress: React.FC<CustomerProfileAddressProps> = (props) => {
  const { className, addressText } = useComponentState(props);

  return (
    <div className={className}>
      <LocationIcon className="shrink-0" />

      <span>{addressText}</span>
    </div>
  );
};
