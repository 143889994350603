import { useLanguage } from '@hooks/useLanguage';
import { ApplicantType } from '@shared/enums';
import { useMemo } from 'react';
import { getValidationSchemaProfile } from './constants';

export function useGetValidationSchemaProfile(applicantType: ApplicantType) {
  const language = useLanguage();

  return useMemo(() => getValidationSchemaProfile(language, applicantType), [language, applicantType]);
}
