import { getRegCodeValidation } from '@shared/utils';
import { FIELD_APPROVE } from '@widgets/signUp/signUpInNeed/signUpInNeedPrivatePerson/constants';
import * as yup from 'yup';
import { FIELD_PASSWORD } from '../signUpVolunteerPrivatePerson/constants';

export const FIELD_LEGAL_NAME: string = 'legalName';
export const FIELD_EMAIL: string = 'email';
export const FIELD_REG_CODE: string = 'regCode';
export const FIELD_CONTACT_NAME: string = 'contactName';
export const FIELD_COUNTRY: string = 'country';
export const FIELD_PHONE: string = 'phone';
export const FIELD_AVATAR: string = 'avatar';

export function getValidationSchemaVolunteerLegalPerson(language: string) {
  const validationSchemaVolunteerLegalPerson = yup.lazy((_, options) => {
    const phoneValidationResult = options.context?.getPhoneInnerValidationResult?.();
    const regCodeBaseValidation = getRegCodeValidation(language);

    return yup.object().shape({
      [FIELD_LEGAL_NAME]: yup.string().trim().required('errors.required'),
      [FIELD_EMAIL]: yup.string().trim().email('errors.invalid-email'),
      [FIELD_REG_CODE]: regCodeBaseValidation.required('errors.required'),
      [FIELD_CONTACT_NAME]: yup.string().trim().required('errors.required'),
      [FIELD_COUNTRY]: yup.string().trim().required('errors.required'),
      [FIELD_PASSWORD]: yup
        .string()
        .trim()
        .required('errors.required')
        .min(6, 'errors.too-short')
        .max(20, 'errors.too-long'),
      [FIELD_APPROVE]: yup.bool().oneOf([true], 'errors.checked'),
      [FIELD_PHONE]: yup
        .string()
        .trim()
        .required('errors.required')
        .matches(/^\+[0-9]+/i, 'errors.invalid-phone-number')
        .test({
          test: () => phoneValidationResult?.isValidPhone || false,
          message: phoneValidationResult?.phoneErrorMessage || '',
        }),
    });
  });

  return validationSchemaVolunteerLegalPerson;
}
