import React, { useEffect, useState } from 'react';
import { Loader } from '@shared/ui/loader/Loader';
import {
  ContactReqFullUser,
  ContactRequestStatus,
  useGetMySendContactRequestsWithPaginationQuery,
  useUpdateContactRequestDeliveredMutation,
} from '@store/api/contact-request.api';
import { CustomerProfileAddress } from '@widgets/customer/profile/customer-profile-address';
import { CustomerProfileAvatar } from '@widgets/customer/profile/customer-profile-avatar';
import { useTranslation } from 'react-i18next';
import { DeleteContactRequestButton } from './DeleteContactRequestButton';
import { Pagination } from '@shared/ui/Pagination';
import { IconNotMark } from '@shared/icons/IconNotMark';
import { PhoneIcon } from '@shared/icons/PhoneIcon';
import { MessageIcon } from '@shared/icons/MessageIcon';
import { Button } from '@shared/ui/buttons/Button';
import { EyeIcon } from '@shared/icons/EyeMark';
import { FaRegUserCircle } from 'react-icons/fa';

type ContactRequestControlProps = {
  id: number;
  message: string;
  status: string;
  userInNeed: ContactReqFullUser;
};

const ContactRequestControl = (props: ContactRequestControlProps) => {
  const { status, id, userInNeed } = props;
  const { t } = useTranslation();

  if (status === ContactRequestStatus.Approved) {
    return (
      <div className="mb-5">
        <Button
          as="link-in"
          to={`/customer/profile/${userInNeed.id}`}
          variant="rich-blue-2"
          size="tiny"
          className="py-0.5 px-5 text-xxs"
        >
          <span className="flex items-center h-[24px]">
            <EyeIcon /> <span style={{ marginLeft: 6 }}>{t('approved-label')}</span>
          </span>
        </Button>
      </div>
    );
  }

  if (status === ContactRequestStatus.NotApproved) {
    return (
      <div className="mb-5">
        <Button as="button" size="tiny" disabled variant="rich-blue-2" className="py-0.5 px-5 text-xxs">
          <span className="flex items-center h-[24px]">
            <IconNotMark />
            {t('not-approved-label')}
          </span>
        </Button>
      </div>
    );
  }

  return <DeleteContactRequestButton crId={id} />;
};

const MySendContactReqCard = ({
  id,
  message,
  status,
  userInNeed,
  isDeliveredToVolunteer,
}: {
  id: number;
  message: string;
  status: string;
  isDeliveredToVolunteer: boolean;
  userInNeed: ContactReqFullUser;
}) => {
  const { t } = useTranslation();

  return (
    <div className="border-2 border-hawkes-blue rounded-lg mt-10 p-4 col-span-2 relative mx-2">
      <CustomerProfileAvatar
        avatar={userInNeed.avatar}
        avatarSize={60}
        className="absolute"
        style={{ top: -30, left: 25 }}
      />
      {!isDeliveredToVolunteer &&
        [ContactRequestStatus.Approved, ContactRequestStatus.NotApproved].includes(status as ContactRequestStatus) && (
          <span
            className="absolute text-xxs"
            style={{
              top: -13,
              left: 100,
              background: '#4db670',
              borderRadius: 12,
              padding: '5px 10px',
              color: 'white',
            }}
          >
            NEW
          </span>
        )}
      <div className="mt-7 mb-5">
        <p className="mb-3 font-normal text-black text-xxs opacity-50">{`${t('who-needs')}:`}</p>
        <div className="flex items-center space-x-2 md:space-x-4 text-xxs text-black">
          <FaRegUserCircle size={24} className="shrink-0" />

          <p className="line-clamp-3">{userInNeed.description}</p>
        </div>
      </div>
      <div className="mb-5">
        <p className="mb-3 font-normal text-black text-xxs opacity-50">{`${t('contact-requests')}:`}</p>
        <div className="flex items-center space-x-2 md:space-x-4 text-xxs text-black">
          <PhoneIcon className="shrink-0" />

          <div>
            {userInNeed.allowSharePhone && <div>{userInNeed.phone || '-'}</div>}
            {userInNeed.allowShareEmail && <div>{userInNeed.email || '-'}</div>}
          </div>
        </div>
      </div>
      <div className="mb-5">
        <p className="mb-3 font-normal text-black text-xxs opacity-50">{`${t('locality')}:`}</p>

        <CustomerProfileAddress address={userInNeed.address} />
      </div>
      <div className="mb-5">
        <p className="mb-3 font-normal text-black text-xxs opacity-50">{`${t('message')}:`}</p>
        <div className="flex items-center space-x-2 md:space-x-4 text-xxs text-black">
          <MessageIcon className="shrink-0" />

          <p>{message}</p>
        </div>
      </div>
      <ContactRequestControl id={id} message={message} status={status} userInNeed={userInNeed} />
    </div>
  );
};

const PER_PAGE = 6;

const useMySendContactReqCards = () => {
  const [page, setPage] = useState(0);
  const { data, isLoading } = useGetMySendContactRequestsWithPaginationQuery(
    {
      page: page + 1,
      perPage: PER_PAGE,
    },
    { refetchOnMountOrArgChange: true },
  );
  const [updateIsdeliveredFlag] = useUpdateContactRequestDeliveredMutation();
  const contactRequests = data?.items || [];
  const total = data?.meta?.total || 0;

  useEffect(() => {
    if (contactRequests.length === 0) return;

    const items = contactRequests.filter(
      (cr) =>
        [ContactRequestStatus.Approved, ContactRequestStatus.NotApproved].includes(cr.status as ContactRequestStatus) &&
        !cr.isDeliveredToVolunteer,
    );
    if (items.length === 0) return;
    const update = async () => {
      try {
        await updateIsdeliveredFlag({ contactRequestIds: items.map((i) => i.id) }).unwrap();
      } catch (error: unknown) {
        console.log(error);
      }
    };
    update();
  }, [contactRequests]);

  return { isLoading, items: contactRequests, page, setPage, total: total / PER_PAGE };
};

export const MySendContactReqCards = () => {
  const { isLoading, items, page, setPage, total } = useMySendContactReqCards();

  if (isLoading) {
    return (
      <div className="mt-6 ml-6">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="grid md:grid-cols-4 lg:grid-cols-6">
        {items.map((cr) => (
          <MySendContactReqCard key={cr.id} {...cr} />
        ))}
      </div>
      <Pagination initialPage={page} onPageChange={(state) => setPage(state.selected)} pageCount={total || 0} />
    </>
  );
};
