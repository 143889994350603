import React from 'react';

import { IconProps } from '@shared/interfaces/icon.interface';

export const LocationIcon: React.FC<IconProps> = ({ width = 24, height = 24, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
      className={className}
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 1118 0z"
      ></path>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 13a3 3 0 100-6 3 3 0 000 6z"
      ></path>
    </svg>
  );
};
