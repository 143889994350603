import React from 'react';

import { Element } from '@shared/interfaces/element.interface';
import { Avatar } from '@shared/ui/Avatar';

import avatarPlaceholderImage from '@assets/images/customer-placeholder.png';

interface CustomerProfileAvatarProps extends Element {
  avatar?: string | null;
  avatarSize?: number;
  style?: React.CSSProperties;
}

const useComponentState = ({ avatar, className, avatarSize, style }: CustomerProfileAvatarProps) => {
  return {
    avatar: avatar || null,
    avatarSize: avatarSize || 96,
    className,
    style,
  };
};

export const CustomerProfileAvatar: React.FC<CustomerProfileAvatarProps> = (props) => {
  const { avatar, avatarSize, className, style } = useComponentState(props);

  return (
    <div className={className} style={style}>
      <Avatar url={avatar} placeholder={avatarPlaceholderImage} size={avatarSize} />
    </div>
  );
};
