import { IconMark } from '@shared/icons/IconMark';
import { User } from '@shared/types/common.types';
import { Link } from '@shared/ui/Link';
import { Button } from '@shared/ui/buttons/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useContactRequestData } from './useContactRequestData';
import clsx from 'clsx';

type ButtonProps = {
  type: 'my-contact' | 'my-send-contact';
  onClick: (v: 'my-contact' | 'my-send-contact') => void;
  count: number;
  className?: string;
};

const CountComponent = ({ count }: { count: number }) => {
  if (!count) return null;

  return (
    <div
      className="absolute w-6 h-6 text-white flex items-center justify-center bg-blue rounded-full"
      style={{ top: -5, right: -5, zIndex: 10 }}
    >
      {count}
    </div>
  );
};

const MyContactButton = ({ type, onClick, className, count }: ButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button
      variant={type === 'my-contact' ? 'primary-invert-2' : 'chip-2'}
      onClick={() => onClick('my-contact')}
      as="button"
      size="small"
      className={clsx(className, 'relative')}
    >
      <CountComponent count={count} />
      <span className="flex">
        {type === 'my-contact' && <IconMark size={18} />}
        {t('contact-request.link')}
      </span>
    </Button>
  );
};

const MySendContactButton = ({ type, onClick, className, count }: ButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button
      variant={type === 'my-send-contact' ? 'primary-invert-2' : 'chip-2'}
      size="small"
      as="button"
      className={clsx(className, 'relative')}
      onClick={() => onClick('my-send-contact')}
    >
      <CountComponent count={count} />
      <span className="flex ">
        {type === 'my-send-contact' && <IconMark size={18} />}
        {t('send-contact-request.link')}
      </span>
    </Button>
  );
};

export const ContactRequestLink = ({
  user,
  ...props
}: {
  user: User;
  type: 'my-contact' | 'my-send-contact';
  onClick: (v: 'my-contact' | 'my-send-contact') => void;
}) => {
  const { t } = useTranslation();

  const { mySendContactCounter, myContactCounter } = useContactRequestData();

  if (user.type === 'volunteer') {
    return (
      <div className="flex mt-5 mb-5">
        <div className="mx-auto flex">
          <MySendContactButton {...props} count={mySendContactCounter} />
          <MyContactButton {...props} count={myContactCounter} className="ml-2" />
        </div>
      </div>
    );
  }

  return (
    <div className="flex mt-5 mb-5">
      <div className="mx-auto flex">
        <MyContactButton {...props} count={myContactCounter} />
        <MySendContactButton {...props} count={mySendContactCounter} className="ml-2" />
      </div>
    </div>
  );
};
