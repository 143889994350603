import { yupResolver } from '@hookform/resolvers/yup';
import { ApplicantType, CONTACT_REQUESTS, Routes, TabEnum } from '@shared/enums';
import { useNotify } from '@shared/hooks/useNotify';
import { AvatarFormInput } from '@shared/ui/avatar/AvatarFormInput';
import { Button } from '@shared/ui/buttons/Button';
import { ApplicantCategoryDropdown } from '@shared/ui/dropdowns/ApplicantCategoryDropdown';
import { CountryDropdown } from '@shared/ui/dropdowns/CountryDropdown';
import { LocalityDropdown } from '@shared/ui/dropdowns/LocalityDropdown';
import { RegionDropdown } from '@shared/ui/dropdowns/RegionDropdown';
import { ErrorList } from '@shared/ui/ErrorList';
import { GenericLink } from '@shared/ui/GenericLink';
import { PhoneInput, usePhoneInnerValidation } from '@shared/ui/PhoneInput/PhoneInput';
import { TextareaField } from '@shared/ui/TextareaField';
import { TextField } from '@shared/ui/TextField';
import { isApiError, translateApiError } from '@shared/utils/errors';
import { useUpdateUserProfileMutation } from '@store/api/user.api';
import {
  FIELD_ABOUT,
  FIELD_APPLICANT_TYPE,
  FIELD_DISTRICT,
  FIELD_EMAIL,
  FIELD_LOCALITY,
  FIELD_NAME,
  FIELD_PHONE,
  FIELD_REGION,
  FIELD_SOCIAL_TAG,
  FIELD_STREET,
  FIELD_AVATAR,
  FIELD_ALLOW_SHARE_PHONE,
  FIELD_ALLOW_SHARE_EMAIL,
} from '@widgets/signUp/signUpInNeed/signUpInNeedPrivatePerson/constants';
import {
  FIELD_COUNTRY,
  FIELD_LEGAL_NAME,
  FIELD_REG_CODE,
} from '@widgets/signUp/signUpVolunteer/signUpVolunteerLegalPerson/constants';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ApplicantTypeTabs } from './ApplicantTypeTabs';
import { ProfileOauthProviders } from './ProfileOauthProviders';
import { useGetValidationSchemaProfile } from './useGetValidationSchemaProfile';
import { createProfilePostData } from './utils';
// import { CheckboxNotFormField } from '@shared/ui/CheckboxNotFormField';
import { ContactProfileForm } from './ContactProfileForm';
import { useSearchParams } from '@shared/hooks/useSearchParams';

export const ProfileForm = ({ profile }: any) => {
  const params = useSearchParams();

  const [applicantType, setApplicantType] = useState<TabEnum>(
    params?.get('tab') === 'cr' ? CONTACT_REQUESTS : profile.applicantType,
  );

  const [apiErrors, setApiErrors] = useState<string[]>([]);
  const [updateUserProfile] = useUpdateUserProfileMutation();
  const { t } = useTranslation();
  const notify = useNotify();
  const { getPhoneInnerValidationResult, setPhoneInnerValidationResult } = usePhoneInnerValidation(profile.phoneNumber);
  const validationSchemaProfile = useGetValidationSchemaProfile(applicantType);

  const isLegalEntityApplicantType = applicantType === ApplicantType.LegalEntity;
  const isContact = applicantType === CONTACT_REQUESTS;

  const defaultValues = {
    [FIELD_AVATAR]: profile.avatar || null,
    [FIELD_APPLICANT_TYPE]: profile.applicantType,
    [FIELD_NAME]: profile.contactName,
    [FIELD_PHONE]: profile.phoneNumber || '',
    [FIELD_COUNTRY]: profile.address.country || 'ua',
    [FIELD_REGION]: profile.address.region?.id ?? '',
    [FIELD_LOCALITY]: profile.address.locality?.id ?? '',
    [FIELD_DISTRICT]: profile.address.district || '',
    [FIELD_STREET]: profile.address.streetName || '',
    [FIELD_ABOUT]: profile.description || '',
    [FIELD_EMAIL]: profile.email || '',
    [FIELD_SOCIAL_TAG]: profile.groups || [],
    [FIELD_ALLOW_SHARE_PHONE]: profile.allowSharePhone || false,
    [FIELD_ALLOW_SHARE_EMAIL]: profile.allowShareEmail || false,
  };

  if (isLegalEntityApplicantType) {
    defaultValues[FIELD_LEGAL_NAME] = profile.legalName || '';
    defaultValues[FIELD_REG_CODE] = profile.regCode || '';
  }

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchemaProfile),
    context: {
      getPhoneInnerValidationResult,
    },
  });

  const {
    handleSubmit,
    control,
    watch,
    formState: { isSubmitting, isValidating, errors, dirtyFields },
    getValues,
    setValue,
  } = methods;

  const isSelectedCountryUkraine = watch(FIELD_COUNTRY) === 'ua';
  const isPhoneDisabled = useMemo(() => (profile.phoneNumber ? true : false), [profile.phoneNumber]);

  const handleApplicantTabChange = React.useCallback(
    (key: TabEnum) => {
      setApplicantType(key);
      setValue(FIELD_APPLICANT_TYPE, key, { shouldDirty: true });
    },
    [setApplicantType, setValue],
  );

  useEffect(() => {
    if (dirtyFields[FIELD_REGION]) {
      setValue(FIELD_LOCALITY, '');
    }
  }, [watch(FIELD_REGION)]);

  const onSubmit = async (data: any) => {
    const dirtyKeys = Object.keys(dirtyFields);
    const body = createProfilePostData(dirtyKeys, data);

    try {
      await updateUserProfile(body).unwrap();
      notify('notifications.profile-updated', { autoClose: 2000 });
      setApiErrors([]);
    } catch (error) {
      if (isApiError(error)) {
        setApiErrors(translateApiError(error, t));
      }
    }
  };

  const isSubmitBtnDisabled = isSubmitting || isValidating || Object.keys(dirtyFields).length <= 0;

  return (
    <FormProvider {...methods}>
      <ApplicantTypeTabs activeTab={applicantType} onTabChange={handleApplicantTabChange} className="mt-8 md:mt-12" />
      {!isContact && (
        <form onSubmit={handleSubmit(onSubmit)} className="max-w-[528px] mx-auto">
          <ProfileOauthProviders profile={profile} />
          <div className="sign-up-form-card">
            <AvatarFormInput />
            <TextField id={FIELD_NAME} label="full-name-of-contact-person" className="mt-10" />
            <Controller
              name={FIELD_COUNTRY}
              control={control}
              render={({ field: { value, onChange, onBlur } }) => (
                <CountryDropdown
                  value={value as string}
                  className="mt-10"
                  errorText={errors[FIELD_COUNTRY]?.message}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
            <TextField id={FIELD_EMAIL} label="email" className="mt-10" />
            <Controller
              name={FIELD_PHONE}
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <PhoneInput
                    value={value}
                    onChange={onChange}
                    label="Phone number"
                    className="mt-10"
                    isDisabled={isPhoneDisabled}
                    helperText={
                      <div>
                        <GenericLink type="page" className="text-shamrock hover:underline" to={Routes.AuthUpdatePhone}>
                          {t('change-phone')}
                        </GenericLink>
                      </div>
                    }
                    errorText={errors[FIELD_PHONE]?.message}
                    setPhoneInnerValidationResult={setPhoneInnerValidationResult}
                  />
                );
              }}
            />
            {isLegalEntityApplicantType ? (
              <>
                <TextField id={FIELD_LEGAL_NAME} label="company-name" className="mt-10" />
                <TextField id={FIELD_REG_CODE} label="usreou-code" className="mt-10" />
              </>
            ) : (
              <>
                {isSelectedCountryUkraine && (
                  <>
                    <Controller
                      name={FIELD_REGION}
                      control={control}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <RegionDropdown
                          value={value as string}
                          className="mt-10"
                          errorText={errors[FIELD_REGION]?.message}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />

                    <Controller
                      name={FIELD_LOCALITY}
                      control={control}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <LocalityDropdown
                          defaultSearch={profile.address.locality?.name || ''}
                          regionId={getValues(FIELD_REGION)}
                          value={value as string}
                          onChange={onChange}
                          onBlur={onBlur}
                          className="mt-10"
                          errorText={errors[FIELD_LOCALITY]?.message}
                          disabled={!getValues(FIELD_REGION)}
                        />
                      )}
                    />

                    <TextField id={FIELD_DISTRICT} label="area" className="mt-10" />
                    <TextField id={FIELD_STREET} label="street" className="mt-10" />
                  </>
                )}
                <Controller
                  name={FIELD_SOCIAL_TAG}
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    return <ApplicantCategoryDropdown value={value} onChange={onChange} className="mt-10" />;
                  }}
                />
                <TextareaField id={FIELD_ABOUT} label="about-me" placeholder="about-me-extended" className="mt-10" />
                {/* <Controller
                  name={FIELD_ALLOW_SHARE_EMAIL}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <CheckboxNotFormField
                      name={FIELD_ALLOW_SHARE_EMAIL}
                      isChecked={value}
                      className="mt-5"
                      label={t('user-settings.allow-email-label')}
                      onChange={onChange}
                    />
                  )}
                />
                <Controller
                  name={FIELD_ALLOW_SHARE_PHONE}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <CheckboxNotFormField
                      name={FIELD_ALLOW_SHARE_PHONE}
                      isChecked={value}
                      className="mt-5"
                      label={t('user-settings.allow-phone-label')}
                      onChange={onChange}
                    />
                  )}
                /> */}
              </>
            )}

            <ErrorList errors={apiErrors} className="mt-6" />
          </div>
          <div className="flex justify-center mt-6">
            <Button as="button" variant="primary" type="submit" disabled={isSubmitBtnDisabled}>
              {t('save')}
            </Button>
          </div>
        </form>
      )}
      {isContact && <ContactProfileForm profile={profile} />}
    </FormProvider>
  );
};
