import { Loader } from '@shared/ui/loader/Loader';
import { useGetUserProfileQuery } from '@store/api/user.api';
import React from 'react';
import { ProfileForm } from './ProfileForm';

export const ProfileFormWrapper = () => {
  const { data: profileData, isLoading } = useGetUserProfileQuery({});

  if (isLoading) return <Loader className="mx-auto" />;
  if (profileData) return <ProfileForm profile={profileData} />;
  return null;
};
