import React from 'react';

import { OauthProvider } from '@shared/enums';
import { useIsSpecificLanguage } from '@shared/hooks/useIsSpecificLanguage';

import { IdGovUaButton } from '@widgets/auth/IdGovUaConnect';
import { useOauthSearchParam } from '@widgets/auth/useOauthSearchParam';

interface ProfileOauthProvidersProps {
  profile: any;
}

export const ProfileOauthProviders: React.FC<ProfileOauthProvidersProps> = ({ profile }) => {
  const isLanguageUk = useIsSpecificLanguage('uk');
  if (!isLanguageUk) {
    return null;
  }

  const isIdGovUaVerified = profile.oauth.includes(OauthProvider.IdGovUa);

  return (
    <div className="flex justify-center">
      <IdGovUaButton
        text={isIdGovUaVerified ? 'Верифiковано через' : 'Верифiкуватися через'}
        disabled={isIdGovUaVerified}
      />
    </div>
  );
};
