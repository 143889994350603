import { useGetIconOptimized } from '@shared/hooks/useGetIconOptimized';
import { useProfile } from '@shared/hooks/useProfile';
import { EyeIcon } from '@shared/icons/EyeMark';
import { IconMark } from '@shared/icons/IconMark';
import { IconNotMark } from '@shared/icons/IconNotMark';
import { Button } from '@shared/ui/buttons/Button';
import { ModalBasic } from '@shared/ui/modal/ModalBasic';
import { ContactRequestStatus, useUpdateContactRequestStatusMutation } from '@store/api/contact-request.api';
import { useMeQuery } from '@store/api/user.api';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type ApproveContactRequestButtonProps = {
  contactRequestId: number;
  status: string;
};

type ModalProps = {
  isVisible: boolean;
  onClose: () => void;
  onApproveHandler: () => void;
  disabled: boolean;
  isLoading: boolean;
};

const ApproveModal = ({ isVisible, onClose, onApproveHandler, isLoading, disabled }: ModalProps) => {
  const iconOptimized = useGetIconOptimized('circle-ukraine-heart');
  const { t } = useTranslation();
  const profile = useProfile();

  return (
    <ModalBasic
      isVisible={isVisible}
      title="share-contact-request"
      secondaryBtnLabel="cancel"
      approveBtnLabel="confirm"
      imageHeading={{ optimized: iconOptimized }}
      onClose={onClose}
      onApprove={onApproveHandler}
      isLoading={isLoading}
      isApproveBtnDisabled={disabled}
    >
      {profile?.allowShareEmail && (
        <div style={{ display: 'flex' }} className="mt-2">
          <IconMark /> <span className="ml-2">{t('email')}</span>
        </div>
      )}
      {profile?.allowSharePhone && (
        <div style={{ display: 'flex' }} className="mt-2">
          <IconMark /> <span className="ml-2">{t('phone-number')}</span>
        </div>
      )}
    </ModalBasic>
  );
};

const NotApproveModal = ({ isVisible, onClose, onApproveHandler, isLoading, disabled }: ModalProps) => {
  const iconOptimized = useGetIconOptimized('help-hands');
  return (
    <ModalBasic
      isVisible={isVisible}
      title="delete-contact-request.label"
      secondaryBtnLabel="cancel"
      approveBtnLabel="confirm"
      imageHeading={{ optimized: iconOptimized }}
      onClose={onClose}
      onApprove={onApproveHandler}
      isLoading={isLoading}
      isApproveBtnDisabled={disabled}
    />
  );
};

export const ApproveContactRequestButton = ({ contactRequestId, status }: ApproveContactRequestButtonProps) => {
  const { t } = useTranslation();
  const [isApproveVisible, setIsApproveVisible] = useState(false);
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);

  const [isNotApproveVisible, setIsNotApproveVisible] = useState(false);
  const [isLoadingNotApprove, setIsLoadingNotApprove] = useState(false);

  const [updateContactRequest] = useUpdateContactRequestStatusMutation();

  const onApprove = async () => {
    try {
      setIsLoadingApprove(true);
      await updateContactRequest({ id: contactRequestId, status: ContactRequestStatus.Approved }).unwrap();
      setIsApproveVisible(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingApprove(false);
    }
  };

  const onNotApprove = async () => {
    try {
      setIsLoadingNotApprove(true);
      await updateContactRequest({ id: contactRequestId, status: ContactRequestStatus.NotApproved }).unwrap();
      setIsNotApproveVisible(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingNotApprove(false);
    }
  };

  if ([ContactRequestStatus.Pending, ContactRequestStatus.Created].includes(status as ContactRequestStatus)) {
    return (
      <div>
        <Button
          as="button"
          onClick={() => setIsApproveVisible(true)}
          variant="rich-blue-2"
          size="tiny"
          className="h-[24px] text-xxs py-0.5 px-5"
        >
          {t('approve-contact-request-status')}
        </Button>
        <Button
          as="button"
          onClick={() => setIsNotApproveVisible(true)}
          variant="rich-blue-2"
          size="tiny"
          className="h-[24px] text-xxs py-0.5 px-5 whitespace-nowrap ml-2"
        >
          {t('not-approve-contact-request-status')}
        </Button>
        <ApproveModal
          isVisible={isApproveVisible}
          onClose={() => setIsApproveVisible(false)}
          onApproveHandler={onApprove}
          isLoading={isLoadingApprove}
          disabled={false}
        />
        <NotApproveModal
          isVisible={isNotApproveVisible}
          onClose={() => setIsNotApproveVisible(false)}
          onApproveHandler={onNotApprove}
          isLoading={isLoadingNotApprove}
          disabled={false}
        />
      </div>
    );
  }

  return (
    <div>
      {status === ContactRequestStatus.Approved && (
        <Button
          as="button"
          disabled
          variant="rich-blue-2"
          size="tiny"
          className="flex h-8 text-xxs items-center py-0.5"
        >
          <EyeIcon />
          <span style={{ marginLeft: 6 }}>{t('approved-label')}</span>
        </Button>
      )}
      {status === ContactRequestStatus.NotApproved && (
        <Button
          disabled
          as="button"
          variant="rich-blue-2"
          size="tiny"
          className="flex h-8 text-xxs items-center py-0.5"
        >
          <IconNotMark /> {t('not-approved-label')}
        </Button>
      )}
    </div>
  );
};
