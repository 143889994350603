import { MessageIcon } from '@shared/icons/MessageIcon';
import { PhoneIcon } from '@shared/icons/PhoneIcon';
import { Pagination } from '@shared/ui/Pagination';
import { Loader } from '@shared/ui/loader/Loader';
import {
  ContactReqFullUser,
  ContactRequestStatus,
  useGetSendContactRequestsWithPaginationQuery,
  useUpdatePendingStatusMutation,
} from '@store/api/contact-request.api';
import { CustomerProfileAddress } from '@widgets/customer/profile/customer-profile-address';
import { CustomerProfileAvatar } from '@widgets/customer/profile/customer-profile-avatar';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApproveContactRequestButton } from './ApproveContactRequestButton';

const MyContactReqCard = ({
  id,
  status,
  volunteer,
  message,
}: {
  id: number;
  message: string;
  status: string;
  volunteer: ContactReqFullUser;
}) => {
  const { t } = useTranslation();

  return (
    <div className="border-2 border-hawkes-blue rounded-lg mt-10 p-4 col-span-2 relative mx-2">
      <CustomerProfileAvatar
        avatar={volunteer.avatar}
        avatarSize={60}
        className="absolute"
        style={{ top: -30, left: 25 }}
      />
      {![ContactRequestStatus.Approved, ContactRequestStatus.NotApproved].includes(status as ContactRequestStatus) && (
        <span
          className="absolute text-xxs"
          style={{
            top: -13,
            left: 100,
            background: '#4db670',
            borderRadius: 12,
            padding: '5px 10px',
            color: 'white',
          }}
        >
          NEW
        </span>
      )}
      <div className="mt-8 mb-3">
        <p className="mb-3 font-normal text-black text-xxs opacity-50">{t('volunteer.label')}</p>
        <div className="flex items-center space-x-2 md:space-x-4 text-xxs text-black">{volunteer.name}</div>
      </div>
      {/* do not share volunteer contacts */}
      {/* <div className="mt-3 mb-5">
        <p className="mb-3 font-normal text-black text-xxs opacity-50">{t('contact-requests')}</p>
        <div className="flex items-center space-x-2 md:space-x-4 text-xxs text-black">
          <PhoneIcon className="shrink-0" />

          <div className="h-8 flex items-start flex-col justify-center">
            <div className="mb-1">{volunteer.phone}</div>
            <div className="mb-1">{volunteer.email}</div>
          </div>
        </div>
      </div> */}
      <div className="mb-5">
        <p className="mb-3 font-normal text-black text-xxs opacity-50">{`${t('locality')}:`}</p>

        <CustomerProfileAddress address={volunteer.address} />
      </div>

      <div className="mb-5">
        <p className="mb-3 font-normal text-black text-xxs opacity-50">{`${t('message')}:`}</p>
        <div className="flex items-center space-x-2 md:space-x-4 text-xxs text-black">
          <MessageIcon className="shrink-0" />

          <p>{message}</p>
        </div>
      </div>

      <div>
        <ApproveContactRequestButton contactRequestId={id} status={status} />
      </div>
    </div>
  );
};

const PER_PAGE = 6;

const useMyContactReqCards = () => {
  const [page, setPage] = useState(0);
  const { data, isLoading } = useGetSendContactRequestsWithPaginationQuery({ page: page + 1, perPage: PER_PAGE });
  const items = data?.items || [];
  const [updatePending] = useUpdatePendingStatusMutation();
  const [pendingLoading, setPendingLoading] = useState(false);

  useEffect(() => {
    if (items.length === 0) return;
    const itemToUpdate = items.filter((cr) => cr.status === ContactRequestStatus.Created);

    if (itemToUpdate.length === 0) return;

    const updateStatus = async () => {
      try {
        setPendingLoading(true);
        updatePending({ items: itemToUpdate.map((i) => i.id) }).unwrap();
      } catch (error) {
        console.log(error);
      } finally {
        setPendingLoading(false);
      }
    };
    updateStatus();
  }, [items]);

  const total = data?.meta?.total || 0;
  return { isLoading, items, page, setPage, total: total / PER_PAGE, pendingLoading };
};

export const MyContactReqCards = () => {
  const { isLoading, items, page, setPage, total, pendingLoading } = useMyContactReqCards();

  if (isLoading || pendingLoading) {
    return (
      <div className="mt-4 ml-4">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="grid md:grid-cols-4 lg:grid-cols-6">
        {items.map((cr) => (
          <MyContactReqCard key={cr.id} {...cr} />
        ))}
      </div>
      <Pagination initialPage={page} onPageChange={(state) => setPage(state.selected)} pageCount={total || 0} />
    </>
  );
};
