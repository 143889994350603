import * as React from 'react';

interface IconMarkProps {
  className?: string;
  onClick?: () => void;
  size?: number;
}

export const IconMark: React.FC<IconMarkProps> = ({ size }) => {
  return (
    <svg width={size || 24} height={size || 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 8.4375L10.4375 15L7 11.8182"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
