import { ApplicantType, CONTACT_REQUESTS, TabEnum } from '@shared/enums';
import { TabBasic } from '@shared/ui/tabs/TabBasic';
import { profileTabsData } from '@widgets/signUp/constants';
import React from 'react';
import { useContactRequestData } from './useContactRequestData';

interface Props {
  activeTab: ApplicantType;
  onTabChange: (key: TabEnum) => void;
  className?: string;
}

const ApplicationTypeTab = (props: {
  isActive: boolean;
  label: string;
  eventKey: ApplicantType | typeof CONTACT_REQUESTS;
  count?: number;
  onClick: (key: string) => void;
}) => {
  if (!props.count) {
    return <TabBasic {...props} />;
  }

  return (
    <TabBasic {...props} className="relative">
      <div
        className="absolute bg-black text-white p-2 rounded-full text-xxs w-8 h-8 justify-center items-canter flex"
        style={{ top: -36, left: '50%', transform: 'translateX(-50%)' }}
      >
        {props.count}
      </div>
    </TabBasic>
  );
};

export const ApplicantTypeTabs: React.FC<Props> = ({ activeTab, onTabChange, className = '' }) => {
  const { count } = useContactRequestData();
  const tabList = React.useMemo(
    () =>
      profileTabsData.map((item, index) => ({
        ...item,
        count: index === profileTabsData.length - 1 ? count : undefined,
        isActive: item.eventKey === activeTab,
      })),
    [activeTab, count],
  );

  return (
    <ul className={`flex space-x-4 md:justify-center md:space-x-8 ${className}`}>
      {tabList.map((item) => (
        <ApplicationTypeTab key={item.eventKey} {...item} onClick={onTabChange as (k: string) => void} />
      ))}
    </ul>
  );
};
