import { useMeQuery } from '@store/api/user.api';
import React from 'react';
import { MyContactReqCards } from './MyContactReqCards';
import { MySendContactReqCards } from './MySendContactReqCards';

export const ContactRequestsCard = ({ type }: { type: 'my-contact' | 'my-send-contact' }) => {
  const { data: user } = useMeQuery();

  if (!user) return null;

  return (
    <div className="w-full mt-6">
      {type === 'my-send-contact' && <MySendContactReqCards />}
      {type === 'my-contact' && <MyContactReqCards />}
    </div>
  );
};
