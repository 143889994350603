import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PrimaryDropdown } from '@shared/dropdowns/PrimaryDropdown';
import { Option } from '@shared/interfaces/option.interface';
import { Element } from '@shared/interfaces/element.interface';

import countries from '@values/countries';

interface CountryDropdownProps extends Element {
  value: string | null;
  onChange?: (countryCode: string) => void;
  onBlur?: any;
  errorText?: string;
}

export const CountryDropdown: React.FC<CountryDropdownProps> = ({ value, className, errorText, onChange, onBlur }) => {
  const { t } = useTranslation();
  const options: Option[] = useMemo(() => countries.map((item) => ({ label: item.name, value: item.code })), []);

  const onCountryChange = (option: string) => {
    if (onChange) {
      onChange(option);
    }
  };

  return (
    <PrimaryDropdown
      value={value}
      onChange={onCountryChange}
      onBlur={onBlur}
      options={options}
      className={className}
      label={t('country')}
      errorText={errorText && t(errorText)}
      searchable
      helperText={t('helper-select-with-search')}
    />
  );
};
